<template>
  <div class="barcode">
    <div class="goback" @click="goback">
      <img src="../../assets/images/goback.png" alt="" />
    </div>
    <div class="code-box">
      <img src="../../assets/images/mycode.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "barcode",
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.barcode {
  position: relative;
  .goback {
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 10;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .code-box {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>